import React, { useCallback, useEffect, useState } from 'react';

import {
	fetchQuery,
	graphql,
	useRelayEnvironment,
	useRefetchableFragment,
	useQueryLoader,
} from 'react-relay';
import Popup, { type ContentProps } from '@atlaskit/popup';
import type { Nav4NotificationsData$data } from '@atlassian/jira-relay/src/__generated__/Nav4NotificationsData.graphql';
import {
	testIdGenerate,
	testIdConcat,
} from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import type { Nav4Notifications$key } from '@atlassian/jira-relay/src/__generated__/Nav4Notifications.graphql';
import { useIntl } from '@atlassian/jira-intl';
import { lazy } from '@atlassian/react-loosely-lazy';
import { isMobileAndInViewPanelIterationExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import Nav4NotificationsRefetchableQuery, {
	type Nav4NotificationsRefetchableQuery as Nav4NotificationsRefetchableQueryType,
} from '@atlassian/jira-relay/src/__generated__/Nav4NotificationsRefetchableQuery.graphql';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	EXPERIENCE_NAVIGATION_TOP_MENU,
	NAVIGATION_ITEM_ID,
	PACKAGE_NAME,
} from '../../common/constants.tsx';
import { useNavigationItemAnalytics } from '../../controllers/navigation-item-analytics/main.tsx';
import { Notification as NotificationsButton } from '../../common/ui/notification-button/index.tsx';
import { usePolling } from './usePolling.tsx';
import { NotificationsContentWrapper as NotificationContentFrame } from './main.tsx';
import { Nav4NotificationsBadge } from './Nav4NotificationsBadge.tsx';
import { MAX_NOTIFICATION_COUNT } from './constants.tsx';
import messages from './messages.tsx';
import { useRegisterNotificationsInCommandPalette } from './command-palette/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMenu = lazy(
	() =>
		import(
			/* webpackChunkName: "atlassian-navigation.async-notification-menu-native" */ './menu'
		).then(({ Menu }) => Menu),
	{
		ssr: false,
	},
);

type Nav4NotificationsProps = {
	fragmentData: Nav4NotificationsData$data;
};

export const Nav4Notifications = (props: Nav4NotificationsProps) => {
	const { fragmentData } = props;
	const environment = useRelayEnvironment();

	const [_queryRef, loadQuery] = useQueryLoader<Nav4NotificationsRefetchableQueryType>(
		Nav4NotificationsRefetchableQuery,
	);

	const [data] = useRefetchableFragment<
		Nav4NotificationsRefetchableQueryType,
		Nav4Notifications$key
	>(
		graphql`
			fragment Nav4Notifications on Query
			@refetchable(queryName: "Nav4NotificationsRefetchableQuery") {
				notifications {
					unseenNotificationCount
				}
			}
		`,
		fragmentData,
	);

	const { formatMessage } = useIntl();

	// Check for new notifications every 60 seconds. This is currently done through polling because
	// Relay subscriptions are not yet supported server side. This polling will need to be replaced with
	// a subscription in the future (when notifications are migrated to Post Office).
	usePolling(() => {
		fetchQuery(environment, Nav4NotificationsRefetchableQuery, {}).subscribe({
			complete: () => {
				loadQuery({}, { fetchPolicy: 'store-and-network' });
			},
			error: (error: Error) => {
				log.safeErrorWithoutCustomerData(
					`${PACKAGE_NAME}.Nav4Notifications`,
					'Error fetching notification',
					error,
				);
			},
		});
	}, 60000);

	const fetchedNotificationsCount = data.notifications?.unseenNotificationCount ?? 0;

	// Initialise the state to false on page load, but use an effect to update it if the unseen notification
	// count changes (i.e. as a result of polling) to ensure the badge is shown when there are new notifications.
	const [isNotificationCleared, setIsNotificationCleared] = useState(false);
	useEffect(() => {
		if (fetchedNotificationsCount > 0) {
			setIsNotificationCleared(false);
		}
	}, [fetchedNotificationsCount]);

	// If notifications have been cleared, force the badge to be 0. This crudely addresses a race condition where
	// opening the menu (which resets the unseen notification count) does not complete before a refetch completes.
	const notificationsCount = isNotificationCleared ? 0 : fetchedNotificationsCount;

	const testIdPrefix = testIdGenerate('secondary-actions', 'notifications');

	const [isMenuOpen, { toggle: toggleMenu, off: closeMenu, on: openMenu }] = useTopMenus(
		MENU_ID.NOTIFICATIONS,
	);

	const content = useCallback(
		({ update }: ContentProps) => (
			<NotificationContentFrame>
				<JSErrorBoundary
					id="notifications.list.async"
					packageName="AtlassianNavigation"
					teamName="navigation"
				>
					<Placeholder name="notification-menu-native" fallback={null}>
						<LazyMenu scheduleUpdate={update} testIdPrefix={testIdPrefix} />
					</Placeholder>
				</JSErrorBoundary>
			</NotificationContentFrame>
		),

		[testIdPrefix],
	);

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.NOTIFICATIONS,
	});

	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.NOTIFICATIONS,
		analyticsSource: 'atlassian-navigation',
	});

	const onClick = useCallback(() => {
		if (isMenuOpen) {
			performance.clearMarks('notification.list.render.start');
		} else {
			performance.mark('notification.list.render.start');
		}

		onStart();
		toggleMenu();
		triggerAnalytics();

		// Always set isNotificationCleared to true when the menu trigger is clicked
		// that there are no unseen notifications).
		setIsNotificationCleared(true);

		// PLEASE NOTE: Refetching the notifications does not occur here because for some reason
		// it blocks the popup from being closed.
	}, [isMenuOpen, onStart, toggleMenu, triggerAnalytics]);

	useRegisterNotificationsInCommandPalette(onClick);

	const badge = useCallback(
		() => <Nav4NotificationsBadge count={notificationsCount} />,
		[notificationsCount],
	);

	const notificationsLabel =
		notificationsCount < MAX_NOTIFICATION_COUNT
			? formatMessage(messages.label, { count: notificationsCount })
			: formatMessage(messages.overNineNotifications);

	const trigger = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'triggerProps' implicitly has an 'any' type.
		(triggerProps) => (
			<div
				data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}
				data-vc={`atlassian-navigation-notifications${__SERVER__ ? '-ssr' : ''}`}
			>
				<NotificationsButton
					{...triggerProps}
					badge={badge}
					isSelected={isMenuOpen}
					onClick={onClick}
					tooltip={formatMessage(messages.tooltip)}
					label={notificationsLabel}
					onViewRequests={openMenu}
				/>
			</div>
		),
		[badge, formatMessage, isMenuOpen, notificationsLabel, onClick, openMenu, testIdPrefix],
	);

	return (
		<Popup
			content={content}
			isOpen={isMenuOpen}
			onClose={closeMenu}
			placement="bottom-end"
			trigger={trigger}
			shouldRenderToParent
			shouldFitViewport={isMobileAndInViewPanelIterationExperiment() ? true : undefined}
		/>
	);
};
