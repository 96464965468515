import cond from 'lodash/cond';
import constant from 'lodash/constant';
import otherwise from 'lodash/stubTrue';
import { fg } from '@atlassian/jira-feature-gating';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import {
	getIsAdmin,
	getIsNav4TenantOptIn,
	getIsNav4TenantOptOut,
	getIsNav4UserOptIn,
	getIsNav4UserOptOut,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';
import type { Nav4RolloutGa, Nav4RolloutMobile, Nav4RolloutNone } from '../../../types.tsx';
import { NAV4_ROLLOUT_MOBILE, NAV4_ROLLOUT_NONE } from '../../../constants.tsx';

// eslint-disable-next-line jira/ff/inline-usage
export const getNav4RolloutForGaAndBeyond = cond<
	Nav4RolloutNone | Nav4RolloutGa | Nav4RolloutMobile
>([
	// Later GA phases eclipse earlier phases.
	[() => fg('jira_nav4_ga_phase-3'), () => ({ stage: 'ga', phase: 3 })],
	[
		() => fg('jira_nav4_ga_phase-2'),
		() => ({ stage: 'ga', phase: 2, isUserOptOut: getIsNav4UserOptOut() }),
	],
	[
		() => fg('jira_nav4_ga_phase-1-5'),
		() => ({
			stage: 'ga',
			phase: 1.5,
			isAdmin: getIsAdmin(),
			isTenantOptOut: getIsNav4TenantOptOut(),
			isUserOptOut: getIsNav4UserOptOut(),
		}),
	],
	[
		() => fg('jira_nav4_ga_phase-1'),
		() => ({
			stage: 'ga',
			phase: 1,
			isAdmin: getIsAdmin(),
			isTenantOptIn: getIsNav4TenantOptIn(),
			isUserOptIn: getIsNav4UserOptIn(),
			isUserOptOut: getIsNav4UserOptOut(),
		}),
	],
	[
		() => fg('jira_nav4_ga_sandboxes'),
		() => ({ stage: 'ga', cohort: 'sandboxes', isUserOptIn: getIsNav4UserOptIn() }),
	],
	[() => isMobileAndInMvpOrExistingUsersExperiment(), constant(NAV4_ROLLOUT_MOBILE)],
	[otherwise, constant(NAV4_ROLLOUT_NONE)],
]);
