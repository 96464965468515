import React, { useEffect, useMemo, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { getTrialDaysLeft } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/utils.tsx';
import { PREMIUM_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { EditionAwarenessContainer } from '../../common/ui/styled/styled.tsx';
import { canProductUserPay } from '../../controllers/billing-information/can-product-user-pay/index.tsx';
import {
	getBillingInformation,
	getBillingInformationForTrialButton,
} from '../../controllers/billing-information/main.tsx';
import { TrialPillButton } from './trial-pill-button/index.tsx';
import type { EntitlementDetails } from './trial-pill-button/types.tsx';
import type { TrialComponentProps, TrialPillProps } from './types.tsx';
import { getIneligibilityReasons, transformError, useEligibilityCheckEvent } from './utils.tsx';

const TrialPillComponent = ({
	edition,
	productKey,
	projectType,
	entitlementDetails,
}: TrialComponentProps) => {
	const { billingSourceSystem } = entitlementDetails;

	const [shouldRenderPill, setShouldRenderPill] = useState<boolean | null>(null);

	const relayEnvironment = useRelayEnvironment();
	const cloudId = useCloudId();
	const fireEligibilityCheckEvent = useEligibilityCheckEvent({
		productKey,
		projectType,
		edition,
		entitlementDetails,
	});

	const {
		loading: billingInformationLoading,
		data: billingInformation,
		error: billingInformationError,
		fetch: fetchBillingInformation,
	} = useService(getBillingInformation);

	const { isOnCMDBPage, getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();

	useEffect(() => {
		fetchBillingInformation({
			cloudId,
			productKey,
			relayEnvironment,
		});
	}, [fetchBillingInformation, cloudId, productKey, relayEnvironment]);

	useEffect(() => {
		if (billingInformationError) {
			fireEligibilityCheckEvent('error', {
				error: transformError(billingInformationError),
			});
			setShouldRenderPill(false);
			return;
		}

		if (billingInformation) {
			const ineligibilityReasons = getIneligibilityReasons(billingInformation);

			if (ineligibilityReasons.length > 0) {
				/*
								We are only checking if the product user can pay when the only ineligibility reason is that they are not a billing admin.
								If there are other ineligibility reasons present, they are not eligible for our experiment.
								 */
				const shouldPerformProductUserChecks =
					billingSourceSystem === 'CCP' &&
					billingInformation.isBillingAdmin === false &&
					ineligibilityReasons.length === 1 &&
					ineligibilityReasons[0] === 'notBillingAdmin';

				if (shouldPerformProductUserChecks) {
					try {
						canProductUserPay(cloudId, productKey, relayEnvironment).then((canPay) => {
							setShouldRenderPill(canPay);
							fireEligibilityCheckEvent(canPay ? null : 'productUserCannotPay', {
								billingInformation,
								ineligibilityReasons,
							});
						});
					} catch (error) {
						fireEligibilityCheckEvent('canProductUserPayError', {
							error: transformError(error),
							billingInformation,
							ineligibilityReasons,
						});
						setShouldRenderPill(false);
					}
				} else {
					// Choose only first reason to keep even backward compatible, other reason could be still retrieved from billingInformation
					fireEligibilityCheckEvent(ineligibilityReasons[0], {
						billingInformation,
						ineligibilityReasons,
					});
					setShouldRenderPill(false);
				}
			} else {
				const notInTrialOrGracePeriod = fg('jira_edition_awareness_isgraceperiod_check_fix')
					? billingInformation.preDunningStatus !== 'IN_PRE_DUNNING' &&
						entitlementDetails.trialEndTime === undefined
					: false;

				if (notInTrialOrGracePeriod) {
					fireEligibilityCheckEvent('notInTrialOrGracePeriod', { billingInformation });
					setShouldRenderPill(false);
				} else {
					fireEligibilityCheckEvent(null, { billingInformation });
					setShouldRenderPill(true);
				}
			}
		} else {
			setShouldRenderPill(false);
		}
	}, [
		billingInformation,
		billingInformationError,
		billingSourceSystem,
		cloudId,
		fireEligibilityCheckEvent,
		productKey,
		relayEnvironment,
		entitlementDetails,
	]);

	if (billingInformationLoading) {
		return <UFOLoadHold name="edition-awareness-billing-information-loading" />;
	}

	if (!billingInformation) {
		return null;
	}

	if (shouldRenderPill) {
		if (
			getIsEligibleForJsmPremiumPillExperiment() &&
			isOnCMDBPage &&
			expValEquals('jsm_premium_trial_pill_experiment', 'cohort', 'control')
		) {
			return null;
		}

		return (
			<EditionAwarenessContainer>
				<TrialPillButton
					productKey={productKey}
					projectType={projectType}
					edition={edition}
					entitlementDetails={entitlementDetails}
					invoiceGroupId={billingInformation.invoiceGroupId}
					transactionAccountId={billingInformation.transactionAccountId}
					isBillingAdmin={billingInformation.isBillingAdmin}
					trialLengthDays={billingInformation.trialLengthDays}
					preDunningStatus={billingInformation.preDunningStatus}
				/>
			</EditionAwarenessContainer>
		);
	}
	return null;
};

export const TrialPill = ({ productKey, projectType, edition }: TrialPillProps) => {
	const [shouldRender, setShouldRender] = useState<boolean | null>(null);

	const { productEntitlementDetails } = useTenantContext();
	const entitlementDetails = productEntitlementDetails?.[productKey];
	const billingSourceSystem = entitlementDetails?.billingSourceSystem || 'HAMS'; // If billingSourceSystem is not set, it is HAMS

	// override the billingSourceSystem to not be null
	const memoizedEntitlementDetails = useMemo<EntitlementDetails>(
		() => ({
			...entitlementDetails,
			billingSourceSystem,
		}),
		[billingSourceSystem, entitlementDetails],
	);

	// Checking whether site is in trial or grace period (14 days after trial ends).
	// We can do this for HAMS but not for CCP. CCP has feature called pre-dunning.
	// We do not know how to identify pre-dunning since new subscription is created without trialEndDate
	// so we basically have to check invoiceability or everyone on CCP
	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, entitlementDetails?.trialEndTime);
	const notInTrialOrGracePeriod =
		billingSourceSystem === 'HAMS' && (!trialDaysLeft || trialDaysLeft < -14);

	const fireEligibilityCheckEvent = useEligibilityCheckEvent({
		productKey,
		projectType,
		edition,
		entitlementDetails,
	});

	useEffect(() => {
		// This check should be always false, and it is here to make sure component works even if it would get mounted outside parent EditionAwareness component
		if (edition !== STANDARD_EDITION && edition !== PREMIUM_EDITION) {
			fireEligibilityCheckEvent('invalidEdition');
			setShouldRender(false);
		} else if (!entitlementDetails) {
			fireEligibilityCheckEvent('missingEntitlementDetails', { productEntitlementDetails });
			setShouldRender(false);
		} else if (notInTrialOrGracePeriod) {
			fireEligibilityCheckEvent('notInTrialOrGracePeriod');
			setShouldRender(false);
		} else {
			setShouldRender(true);
		}
	}, [
		edition,
		entitlementDetails,
		fireEligibilityCheckEvent,
		productEntitlementDetails,
		notInTrialOrGracePeriod,
	]);

	if (shouldRender) {
		return (
			<TrialPillComponent
				edition={edition}
				productKey={productKey}
				projectType={projectType}
				entitlementDetails={memoizedEntitlementDetails}
			/>
		);
	}
	return null;
};

/**
 * Replicates the logic above, without fetching information for eligibility checks
 * as this has been done in the platform EditionAwareness component
 * This only fetches any additional information we need to render the pill i.e. billing info
 */
export const TrialButton = ({ productKey, projectType, edition }: TrialPillProps) => {
	const { isOnCMDBPage, getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	const cloudId = useCloudId();
	const relayEnvironment = useRelayEnvironment();
	const {
		loading: billingInformationLoading,
		data: billingInformation,
		error: billingInformationError,
		fetch: fetchBillingInformation,
	} = useService(getBillingInformationForTrialButton);

	useEffect(() => {
		fetchBillingInformation({
			cloudId,
			productKey,
			relayEnvironment,
		});
	}, [fetchBillingInformation, cloudId, productKey, relayEnvironment]);

	const productEntitlementDetails = useProductEntitlementDetails();
	const entitlementDetails = productEntitlementDetails?.[productKey];
	// If billingSourceSystem is not set, it is HAMS
	const billingSourceSystem = entitlementDetails?.billingSourceSystem || 'HAMS';
	// override the billingSourceSystem to not be null
	const memoizedEntitlementDetails = useMemo<EntitlementDetails>(
		() => ({
			...entitlementDetails,
			billingSourceSystem,
		}),
		[billingSourceSystem, entitlementDetails],
	);

	const fireEligibilityCheckEvent = useEligibilityCheckEvent({
		productKey,
		projectType,
		edition,
		entitlementDetails,
	});

	useEffect(() => {
		if (billingInformationError) {
			fireEligibilityCheckEvent('error', {
				error: transformError(billingInformationError),
				flagKey: 'new_ea_jira_audience_and_timing',
			});
		}
	}, [billingInformationError, fetchBillingInformation, fireEligibilityCheckEvent]);

	if (billingInformationLoading) {
		return <UFOLoadHold name="edition-awareness-billing-information-loading" />;
	}

	if (!billingInformation || billingInformationError) {
		return null;
	}

	const notInTrialOrGracePeriod = fg('jira_edition_awareness_isgraceperiod_check_fix')
		? billingInformation.preDunningStatus !== 'IN_PRE_DUNNING' &&
			memoizedEntitlementDetails?.trialEndTime === undefined
		: false;

	if (notInTrialOrGracePeriod) {
		fireEligibilityCheckEvent('notInTrialOrGracePeriod', { billingInformation });
		return null;
	}

	if (
		getIsEligibleForJsmPremiumPillExperiment() &&
		isOnCMDBPage &&
		expValEquals('jsm_premium_trial_pill_experiment', 'cohort', 'control')
	) {
		return null;
	}

	return (
		<EditionAwarenessContainer>
			<TrialPillButton
				productKey={productKey}
				projectType={projectType}
				edition={edition}
				entitlementDetails={memoizedEntitlementDetails}
				invoiceGroupId={billingInformation.invoiceGroupId}
				transactionAccountId={billingInformation.transactionAccountId}
				isBillingAdmin={billingInformation.isBillingAdmin}
				trialLengthDays={billingInformation.trialLengthDays}
				preDunningStatus={billingInformation.preDunningStatus}
			/>
		</EditionAwarenessContainer>
	);
};
