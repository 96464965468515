import React, { useEffect, useMemo, useState } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import {
	PRODUCT_DISCOVERY_PROJECT,
	projectTypeToCanonicalId,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	toProjectType,
	type ProjectType,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { useIsEditionAwarenessPillDismissed } from '@atlassian/jira-edition-awareness-for-standard-to-premium/src/services/is-ea-pill-dismissed/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import {
	ContextualAnalyticsData,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	FREE_EDITION,
	getEditionForProject,
	PREMIUM_EDITION,
	STANDARD_EDITION,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useSiteHasOnlyJira } from '@atlassian/jira-billing/src/controllers/explicitly-licensed-products/use-site-has-only-jira.tsx';
import {
	EditionAwareness as EditionAwarenessImpl,
	type EditionAwarenessProps,
} from '@atlassian/edition-awareness';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { SiteType } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/common/types.tsx';
import { memoizedFetchSitePersonalization } from '@atlassian/jira-personalization-service/src/services/site/index.tsx';
import type { Trait } from '@atlassian/jira-personalization-service/src/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { EDITION_AWARENESS, PACKAGE_NAME } from '../common/constants.tsx';
import { useProductAndEdition } from '../services/use-product-and-edition.tsx';
import { useReverseTrials } from '../services/use-reverse-trials.tsx';
import { EditionAwarenessButton } from './edition-awareness-next/index.tsx';
import { TrialButton, TrialPill } from './trial-pill/index.tsx';
import { getEaEdition, getEaProduct } from './utils.tsx';

// Experiment specific code to be removed during cleanup
const isEnrolledInJPDFreeToStandard = () => {
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

const UFOProductAndEditionCustomData = ({
	projectType,
	applicationEdition,
}: {
	projectType: ProjectType;
	applicationEdition: ApplicationEdition;
}) => {
	const product = getEaProduct(projectType);
	const edition = getEaEdition(applicationEdition);
	return <UFOCustomData data={{ product, edition }} />;
};

export const loadTrait = async (cloudId: string, traitName: string): Promise<Trait | null> => {
	const traits: Trait[] = await memoizedFetchSitePersonalization({ cloudId });
	const foundTrait = traits.find((trait) => trait.name === traitName);
	if (!foundTrait) {
		return null;
	}
	return foundTrait;
};

const EditionAwarenessWithCommonAnalytics = () => {
	const isSiteAdmin = useIsSiteAdmin();

	const appEditions = useAppEditions();
	const { loading, data: project } = useProject();
	const siteHasOnlyJira = useSiteHasOnlyJira();

	const { getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	// Show premium pill button for JSM premium trial experiment

	// If the JSW EA top nav relocation is active, we want to default the project type to JSW
	// so that the pill can show even on product-agnostic pages (e.g. "Your work")
	const defaultProjectType = useMemo(() => {
		if (siteHasOnlyJira) {
			return SOFTWARE_PROJECT;
		}
		if (getIsEligibleForJsmPremiumPillExperiment()) {
			// If the user is on the JSM assets page, we want to default the project type to JSM
			return SERVICE_DESK_PROJECT;
		}
		return undefined;
	}, [getIsEligibleForJsmPremiumPillExperiment, siteHasOnlyJira]);

	const projectType = toProjectType(project?.type) || defaultProjectType;

	if (loading || !projectType) {
		return null;
	}

	const edition = getEditionForProject(projectType, appEditions);

	return (
		<ContextualAnalyticsData
			attributes={{
				projectType,
				applicationEdition: edition,
				siteHasJswOnly: siteHasOnlyJira,
				isSiteAdmin,
			}}
		>
			<ProductAndEditionChecker
				projectType={projectType}
				edition={edition}
				siteHasOnlyJira={siteHasOnlyJira}
			/>
			{fg('new_ea_ufo_custom_data_info') && (
				<UFOProductAndEditionCustomData projectType={projectType} applicationEdition={edition} />
			)}
		</ContextualAnalyticsData>
	);
};

type ProductAndEditionCheckerProps = {
	projectType: ProjectType;
	edition: ApplicationEdition;
	siteHasOnlyJira: boolean;
};

const ProductAndEditionChecker = (props: ProductAndEditionCheckerProps) => {
	const { projectType, edition, siteHasOnlyJira } = props;

	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [siteType, setSiteType] = useState<SiteType | undefined>(undefined);

	// While the migration to the platform component is in progress we need to call the trait on the old component to pass to the FreeButton
	useEffect(() => {
		if (siteType === undefined) {
			if (edition === FREE_EDITION) {
				if (fg('load_growth_cohort_trait')) {
					loadTrait(cloudId, 'growth_discount_cohort')
						.then((trait) => {
							if (trait && (trait.value === 'EDU' || trait.value === 'ORG')) {
								setSiteType(trait.value);
							} else {
								setSiteType('UNKNOWN');
							}
						})
						.catch((e) => {
							setSiteType(null);
							const event = createAnalyticsEvent({});
							fireErrorAnalytics({
								event,
								meta: {
									id: 'editionAwarenessGrowthCohortTrait',
									packageName: 'edition-awareness',
									teamName: 'growth-tako',
								},
								error: e,
								sendToPrivacyUnsafeSplunk: true,
							});
						});
				} else {
					setSiteType(null);
				}
			} else {
				setSiteType(null);
			}
		}
	}, [cloudId, createAnalyticsEvent, edition, siteType]);

	// We need to wait for the siteType to be set before rendering the button so we can send it to the platform component
	if (siteType === undefined) {
		return null;
	}

	if (projectType === SERVICE_DESK_PROJECT) {
		switch (edition) {
			case FREE_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsm-free-to-standard">
						<EditionAwarenessButton
							applicationEdition={edition}
							applicationKey="jira-servicedesk"
							projectType={projectType}
							siteType={siteType}
						/>
					</UFOSegment>
				);
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-jsm-standard-premium-trial">
						<TrialPill productKey="jira-servicedesk" projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === SOFTWARE_PROJECT) {
		switch (edition) {
			case FREE_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-free">
						<EditionAwarenessButton
							applicationEdition={edition}
							applicationKey="jira-software"
							projectType={projectType}
							siteHasJswOnly={siteHasOnlyJira}
							siteType={siteType}
						/>
					</UFOSegment>
				);

			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-jsm-standard-premium-trial">
						<TrialPill productKey="jira-software" projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === PRODUCT_DISCOVERY_PROJECT) {
		if (fg('jpd_disable_edition_awareness')) {
			return null;
		}
		switch (edition) {
			case FREE_EDITION:
				if (isEnrolledInJPDFreeToStandard()) {
					return (
						<UFOSegment name="edition-awareness-jpd-free-to-standard">
							<EditionAwarenessButton
								applicationEdition="FREE_EDITION"
								applicationKey="jira-product-discovery"
								projectType={projectType}
								siteType={siteType}
							/>
						</UFOSegment>
					);
				}

				return null;
			case STANDARD_EDITION:
				return (
					<UFOSegment name="edition-awareness-jpd-standard-premium-trial">
						<TrialPill
							productKey="jira-product-discovery"
							projectType={projectType}
							edition={edition}
						/>
					</UFOSegment>
				);
			// JPD has a premium offering that is currently in beta with a plan to release in FY25 Q3/4
			// We're for now ensuring the experience does not show to customers as it currently shows JSM content
			// and we don't have a set list of features to update the content to.
			case PREMIUM_EDITION:
			default:
				return null;
		}
	} else if (projectType === CORE_PROJECT) {
		// We are supporting only JWM implicit licensing when the parent license is JSW
		switch (edition) {
			case FREE_EDITION:
				if (siteHasOnlyJira) {
					return (
						<UFOSegment name="edition-awareness-jsw-free">
							<EditionAwarenessButton
								applicationEdition={edition}
								applicationKey="jira-software"
								projectType={projectType}
								siteHasJswOnly={siteHasOnlyJira}
								siteType={siteType}
							/>
						</UFOSegment>
					);
				}
				return null;
			case STANDARD_EDITION:
				if (siteHasOnlyJira) {
					// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
					// projectType is the real value ("business").
					return (
						<UFOSegment name="edition-awareness-implicit-jwm-standard-trial">
							<TrialPill edition={edition} productKey="jira-software" projectType={CORE_PROJECT} />
						</UFOSegment>
					);
				}
				return null;
			case PREMIUM_EDITION:
				// Core projects are partially supported - only for implicit JWM, on premium, on the top nav
				if (siteHasOnlyJira) {
					// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
					// projectType is the real value ("business").
					return (
						<UFOSegment name="edition-awareness-implicit-jwm-premium-trial">
							<TrialPill
								edition={edition}
								productKey={projectTypeToCanonicalId(SOFTWARE_PROJECT)}
								projectType={CORE_PROJECT}
							/>
						</UFOSegment>
					);
				}
				return null;
			default:
				return null;
		}
	}
	return null;
};

const EditionAwarenessPlatformInnerWithRTCheck = (props: EditionAwarenessProps) => {
	const { isReady, isInReverseTrial, onOpenOnboardingMenu } = useReverseTrials();

	// We need to wait for the reverse trial check to be complete before
	// rendering the button so we can send it to the platform component
	if (!isReady) {
		return <UFOLoadHold name="edition-awareness-reverse-trial-check-loading" />;
	}

	return (
		<EditionAwarenessImpl
			{...props}
			isInReverseTrial={isInReverseTrial}
			onOpenOnboardingMenu={onOpenOnboardingMenu}
		/>
	);
};

const EditionAwarenessPlatformInner = (props: EditionAwarenessProps) => {
	return <EditionAwarenessImpl {...props} />;
};

const EditionAwarenessPlatform = () => {
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();
	const { product, edition, buttonProps } = useProductAndEdition();
	const { isDismissed, handleDismiss } = useIsEditionAwarenessPillDismissed();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [siteType, setSiteType] = useState<SiteType | undefined>(undefined);

	useEffect(() => {
		if (siteType === undefined) {
			if (edition === 'free') {
				if (fg('load_growth_cohort_trait')) {
					loadTrait(cloudId, 'growth_discount_cohort')
						.then((trait) => {
							if (trait && (trait.value === 'EDU' || trait.value === 'ORG')) {
								setSiteType(trait.value);
							} else {
								setSiteType('UNKNOWN');
							}
						})
						.catch((e) => {
							setSiteType(null);
							const event = createAnalyticsEvent({});
							fireErrorAnalytics({
								event,
								meta: {
									id: 'editionAwarenessGrowthCohortTrait',
									packageName: 'edition-awareness',
									teamName: 'growth-tako',
								},
								error: e,
								sendToPrivacyUnsafeSplunk: true,
							});
						});
				} else {
					setSiteType(null);
				}
			} else {
				setSiteType(null);
			}
		}
	}, [cloudId, createAnalyticsEvent, edition, siteType]);

	if (!product || !edition || !buttonProps) {
		return null;
	}

	// We need to wait for the siteType to be set before rendering the button so we can send it to the platform component
	if (siteType === undefined) {
		return <UFOLoadHold name="edition-awareness-site-type-loading" />;
	}

	const { projectType, applicationEdition, siteHasJswOnly, applicationKey } = buttonProps;

	const editionAwarenessProps: EditionAwarenessProps = {
		freeButton:
			// handle typechecking for free button
			projectType !== CUSTOMER_SERVICE_PROJECT ? (
				<>
					<EditionAwarenessButton
						applicationEdition={FREE_EDITION}
						applicationKey={applicationKey}
						projectType={projectType}
						siteHasJswOnly={siteHasJswOnly}
						siteType={siteType}
					/>
					<UFOCustomData data={{ product, edition }} />
				</>
			) : undefined,
		trialButton:
			// handling type checking for trial button
			applicationEdition === STANDARD_EDITION || applicationEdition === PREMIUM_EDITION ? (
				<>
					<TrialButton
						edition={applicationEdition}
						productKey={applicationKey}
						projectType={projectType}
					/>
					<UFOCustomData data={{ product, edition }} />
				</>
			) : undefined,
		edition,
		cloudId,
		product,
		userRoles: isSiteAdmin ? ['SITE_ADMIN'] : [],
		hasDismissedButton: isDismissed,
		onDismissButton: handleDismiss,
		siteType,
	};

	return (
		<ContextualAnalyticsData
			attributes={{
				projectType,
				applicationEdition,
				siteHasJswOnly,
				isSiteAdmin,
				product,
				edition,
			}}
		>
			{edition === 'premium' && projectType === SOFTWARE_PROJECT ? (
				// We need to call a few hooks to check RT, could be cleaned up in the future
				<EditionAwarenessPlatformInnerWithRTCheck {...editionAwarenessProps} />
			) : (
				<EditionAwarenessPlatformInner {...editionAwarenessProps} />
			)}
		</ContextualAnalyticsData>
	);
};

export const EditionAwareness = () => {
	// This component is not rendered on the server, we don't have enough information to determine if it will be renderer or not
	// Don't remove because it may cause the SSR to fail, at this point it because the EditonAwarenessButton is using useLazyLoadQuery
	if (__SERVER__) {
		return null;
	}

	return (
		<JSErrorBoundary
			id={EDITION_AWARENESS}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			<UFOSegment name="edition-awareness">
				{fg('new_ea_jira_audience_and_timing') ? (
					<EditionAwarenessPlatform />
				) : (
					<EditionAwarenessWithCommonAnalytics />
				)}
			</UFOSegment>
		</JSErrorBoundary>
	);
};
