/**
 * @generated SignedSource<<0e946028ffc3086f1d9efb784e3dbaa5>>
 * @relayHash b27ca3910e82a09b8cdc053e9a61ed19
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f56bec76aa8f8c1f4592fbdd8e4dc7b3b3e88310f264e6c19fece22372dba44e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4NotificationsRefetchableQuery$variables = Record<PropertyKey, never>;
export type Nav4NotificationsRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Notifications">;
};
export type Nav4NotificationsRefetchableQuery = {
  response: Nav4NotificationsRefetchableQuery$data;
  variables: Nav4NotificationsRefetchableQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "Nav4NotificationsRefetchableQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4Notifications"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Nav4NotificationsRefetchableQuery",
    "selections": [
      {
        "concreteType": "InfluentsNotificationQuery",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "unseenNotificationCount"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f56bec76aa8f8c1f4592fbdd8e4dc7b3b3e88310f264e6c19fece22372dba44e",
    "metadata": {},
    "name": "Nav4NotificationsRefetchableQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "9fa4e72bf771cce7bab0cd69ec168049";

export default node;
