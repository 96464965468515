import { useCallback } from 'react';
import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils/src/controllers/use-fetch-onboarding-reverse-trial/index.tsx';
import {
	PREMIUM_REVERSE_TRIAL_TASK,
	ADVANCED_ROADMAPS_TASK,
} from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import { useJswOnboarding } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { useOpenChecklist } from '@atlassian/jira-software-onboarding-quickstart/src/controllers/open-checklist/index.tsx';
import { useIsTailoredOnboardingEnabledAndEligible } from '@atlassian/jira-tailored-onboarding/src/controllers/index.tsx';

type ReverseTrialInfo = {
	isReady: boolean;
	isInReverseTrial: boolean | undefined;
	onOpenOnboardingMenu: (() => void) | undefined;
};

/**
 * Condenses some logic from the Reverse Trial Dropdown. This is used
 * to integrate Jira with the AudienceAndTiming code in Platform.
 * The majority of this code can be removed once `jira_rt_quickstart_m1`
 * is cleaned up due to its inclusion in a holdout
 * @returns { isInReverseTrial, onOpenOnboardingMenu }
 */
export const useReverseTrials = (): ReverseTrialInfo => {
	const openChecklist = useOpenChecklist();
	const [{ currentTaskGroupParent }, { changeTaskGroupParent, nextOpenTask }] = useJswOnboarding();

	const { premiumTrialOnSignup, isReady: isRTReady } = useFetchOnboardingReverseTrial();
	const { isReady: isTOReady, isTailoredOnboardingM3EnabledAndEligible } =
		useIsTailoredOnboardingEnabledAndEligible();

	const onOpenOnboardingMenu = useCallback(() => {
		const taskGroupToEnter = PREMIUM_REVERSE_TRIAL_TASK;

		if (currentTaskGroupParent !== taskGroupToEnter) {
			changeTaskGroupParent(taskGroupToEnter);
		}

		openChecklist({
			trigger: 'ReverseTrialPopup',
		});

		nextOpenTask(ADVANCED_ROADMAPS_TASK, {
			closeNudge: true,
		});
	}, [currentTaskGroupParent, openChecklist, nextOpenTask, changeTaskGroupParent]);

	if (!isRTReady || !isTOReady) {
		return {
			isReady: false,
			isInReverseTrial: undefined,
			onOpenOnboardingMenu: undefined,
		};
	}

	// The check for `jira_rt_quickstart_m1` experiment is performed on platform
	const hidePremiumFeatureItem = isTailoredOnboardingM3EnabledAndEligible;

	return {
		isReady: true,
		isInReverseTrial: premiumTrialOnSignup,
		onOpenOnboardingMenu: hidePremiumFeatureItem ? undefined : onOpenOnboardingMenu,
	};
};
